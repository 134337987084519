import React from 'react'
import TimeLineView from '../TimeLineView'
import { useUser } from '../../../Context/context'
import { Slider } from 'antd';
import { IoBulbOutline } from 'react-icons/io5';

const Dimmer = () => {
    const { device } = useUser();

    const handleChange = value => {
        // console.log(`selected ${value}`);
    };
    return (
        <div style={{ display: 'flex', justifyContent: 'space-evenly', padding: '0px 30px 0px 0px' }}>
            <div style={{width:'100%'}}>
                <div style={{textAlign:'center'}}>
                    <IoBulbOutline style={{ fontSize:'200px'}}/>
                </div>
                <Slider style={{width:'100%'}} defaultValue={30} onChange={handleChange} />

            </div>
            <div className='wlc_timeline_view_container' >
                <TimeLineView applianceId={device?.applianceId} />
            </div>
        </div>
    )
}

export default Dimmer