import { useEffect, useState } from "react";
import { UserService } from "../../../services/UserService";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import {
  Chart,
  Series,
  CommonSeriesSettings,
  Label,
  Format,
  Legend,
  Export,
} from "devextreme-react/chart";
import { Card } from "antd";
import { Column } from "@ant-design/plots";
import ThemeColor from "../../../assets/theme/ThemeColor";
import { useToggleTop } from "../../common/ToggleTop";
const YearChart = () => {
  const [data, setData] = useState([]);
  const params = useLocation();
  const device = params?.state?.data;
  const setToggleTop = useToggleTop();

  const [changeDate, setChangeDate] = useState(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const formattedDate = `${year}`;
    return formattedDate;
  });

  const fetchData = async () => {
    try {
      const res = await UserService.getDoorStatusForYear(
        device.applianceId,
        changeDate
      );
      // date: moment(item.bucket).format("YYYY-MM"),

      const result = res.map((item, index) => {
        const open = {
          date: moment(item.bucket).format("YYYY-MM"),
          // hour: moment(item.bucket).utc().hours(),
          type: 'open-count',
          count: parseInt(item.open_count)
        }
        const close = {
          date: moment(item.bucket).format("YYYY-MM"),
          // hour: moment(item.bucket).utc().hours(),
          type: 'close-count',
          count: parseInt(item.close_count)
        }
        return [open, close];
      }).reduce((acc, current)=>{
        return [...acc, ...current]
      },[])

      setData(result);
    } catch (error) {
      setToggleTop("Error fetching Data !", ThemeColor.red);
      // console.log("YearChartError", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [changeDate]);

  const config = {
    data,
    xField: 'date',//month- date
    yField: 'count',//value- count
    colorField: 'type',//name- type
    group: true,
    scrollbar: {
      x: {}
    },
    style: {
      inset: 5,
      // insetLeft:5,
      // insetRight:20,
      // insetBottom:10
      // insetTop:10
    },
  };
  
  return (
    <Column {...config} />
  );
}

export default YearChart;
