import React, { useEffect, useState } from 'react'
import { useUser } from '../../Context/context'
import CircleBarSpinner from './CircleBarSpinner';
import ThemeColor from '../../assets/theme/ThemeColor';
import { Position } from 'devextreme-react/cjs/autocomplete';
import Overlay from './Overlay';

const SelectDevices = ({ data, setData, handleDefaultChange, handleOnChange, handleOffChange }) => {
    const { allDevices } = useUser();
    const [overlay, setOverlay] = useState('');
    const [targetDevice, setTargetDevice] = useState('');

    const modeSetOpen = (device) => {
        setTargetDevice(device.applianceId);
        setOverlay('open');
    }
    const modeSetClose = () => {
        setOverlay('close');
    }
    const _fan_data = [
        { value: 1, nativeClass_open: 'selectDevices-one-open cursor-pointer', nativeClass_close: 'selectDevices-one-close' },
        { value: 2, nativeClass_open: 'selectDevices-two-open cursor-pointer', nativeClass_close: 'selectDevices-two-close' },
        { value: 3, nativeClass_open: 'selectDevices-three-open cursor-pointer', nativeClass_close: 'selectDevices-three-close' },
        { value: 4, nativeClass_open: 'selectDevices-four-open cursor-pointer', nativeClass_close: 'selectDevices-four-close' },
        { value: 5, nativeClass_open: 'selectDevices-five-open cursor-pointer', nativeClass_close: 'selectDevices-five-close' },
        { value: 6, nativeClass_open: 'selectDevices-six-open cursor-pointer', nativeClass_close: 'selectDevices-six-close' },
        // { value: 'X', nativeClass_open: 'selectDevices-cancel-open cursor-pointer', nativeClass_close: 'selectDevices-cancel-close' }

    ]
    
    return (
        <>
            {
                data
                    ?
                    <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', gap: '50px', overflow: 'scroll' }}>

                        {[...allDevices].map((device, index) => <div key={'all_devices'+index} style={{ display: 'flex', justifyContent: 'space-between', gap: '25px' }}>
                            <div>
                                <div>{device?.name}</div>
                                <div><sub>{device?.room} | {device?.floor}</sub></div>
                            </div>

                            {/* fan middle */}
                            <div>
                                {(device?.deviceType === 'FAN') &&
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div className='cursor-pointer' style={roundedFanButton} onClick={() => modeSetOpen(device)}>
                                                <span>
                                                    {(device.applianceId in data?.data?.control?.action)
                                                        ? { ...data?.['data']?.['control']?.['action']?.[device.applianceId] }.hasOwnProperty('value') ?
                                                            data?.['data']?.['control']?.['action']?.[device.applianceId]?.['value']
                                                            : data?.['data']?.['control']?.['action']?.[device.applianceId]?.['speed']
                                                        : 0}
                                                </span>
                                            </div>

                                        </div>

                                    </div>
                                }

                                {/* WLC middle */}
                                {(device?.meta?.model === 'WLC_1' && (device?.waterFlowType === 'TYPE_1' || device?.waterFlowType === 'TYPE_2' || device?.waterFlowType === 'TYPE_3' || device?.waterFlowType === 'TYPE_6')) &&
                                    <div
                                        className='cursor-pointer'
                                        onClick={() => targetDevice === '' ? setTargetDevice(device.applianceId) : setTargetDevice('')}
                                        style={{ 
                                            // color: ThemeColor.white,
                                            // background:ThemeColor.pirPrimary, 
                                            position: 'relative', 
                                            textAlign: 'center', 
                                            display: 'flex', 
                                            alignItems: 'center', 
                                            justifyContent: 'center',
                                            height:'100%',
                                            width:'80px'
                                        }}
                                    >

                                        {/* motor button */}
                                        <div style={{   
                                            padding: ' 0px 5px',
                                             borderRadius: '7px',
                                             background:ThemeColor.pirPrimary,
                                             width:'100%',
                                             padding:'5px 10px',
                                             color: ThemeColor.white

                                         }}>
                                            {
                                                data?.data?.control?.action?.[device.applianceId]?.id == 1
                                                    ? 'Motor 1'
                                                    : (data?.data?.control?.action?.[device.applianceId]?.id == 2
                                                        ? 'Motor 2'
                                                        : '-'
                                                    )
                                            }
                                        </div>

                                        {/* motor button Drop down */}
                                        {targetDevice === device.applianceId && <div
                                            style={{
                                                position: 'absolute',
                                                boxShadow: '1px 1px 10px -4px grey',
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                // gap: '20px',
                                                top: '45px'
                                            }}>
                                            <div
                                                style={data?.data?.control?.action?.[device.applianceId]?.id == 1 ? { ...motorButton, ...motorButtonSelected } : { ...motorButton }}
                                                onClick={() => {
                                                    data?.data?.control?.action?.[device.applianceId]?.value === 0
                                                        ? handleOffChange({ device, mode: 'OFF', wlcmotor: 1 })
                                                        : handleOnChange({ device, mode: 'ON', wlcmotor: 1 })
                                                    modeSetClose(device);
                                                }}
                                            >Motor 1</div>
                                            <div
                                                style={data?.data?.control?.action?.[device.applianceId]?.id == 2 ? { ...motorButton,  ...motorButtonSelected, } : { ...motorButton }}
                                                onClick={() => {
                                                    data?.data?.control?.action?.[device.applianceId]?.value === 0
                                                        ? handleOffChange({ device, mode: 'OFF', wlcmotor: 2 })
                                                        : handleOnChange({ device, mode: 'ON', wlcmotor: 2 })
                                                    modeSetClose(device);
                                                }

                                                }
                                            >Motor 2</div>
                                        </div>}
                                    </div>
                                }
                                {/*  range slider */}
                               {device.deviceType === 'DIMMER_MODULE' && 
                               <div style={{}}>
                                    <div style={{textAlign:'center'}}>{data?.data?.control?.action?.[device.applianceId]?.value || 0}%</div>
                                    <div><input type="range" min="1" max="100" id="myRange" defaultValue={data?.data?.control?.action?.[device.applianceId]?.value || 0} onChange={(e)=>handleOnChange({ device, dimValue: Number(e.target.value) })}/></div>
                                    {/* <input type='range' min={0} max={100} style={{color:ThemeColor.pirPrimary, background:ThemeColor.pirPrimary}}/> */}
                                </div>}
                                
                                {/* Overlay */}
                                {(targetDevice === device.applianceId && device.deviceType === 'FAN') &&
                                    <Overlay zIndex={23} overlay={overlay} setOverlay={setOverlay}>
                                        {/* FAN */}
                                        {device.deviceType === 'FAN'
                                            ?
                                            (<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                {_fan_data.map((item, index_2) => <div key={'fan-aniation' + index_2}
                                                    className={overlay === 'open' ? item.nativeClass_open : item.nativeClass_close}
                                                    style={
                                                        data?.data?.control?.action?.hasOwnProperty(device.applianceId) &&
                                                        (
                                                            data?.data?.control?.action[device.applianceId]?.hasOwnProperty('speed')
                                                                ? (Number(data?.data?.control?.action[device.applianceId]?.speed) === item.value
                                                                    ? roundedFanButtonAbsolute_active
                                                                    : roundedFanButtonAbsolute_passive
                                                                )
                                                                : (data?.data?.control?.action[device.applianceId]?.hasOwnProperty('value')
                                                                    ? (Number(data?.data?.control?.action[device.applianceId]?.value) === item.value
                                                                        ? roundedFanButtonAbsolute_active
                                                                        : roundedFanButtonAbsolute_passive
                                                                    )
                                                                    : roundedFanButtonAbsolute_passive
                                                                )
                                                        ) || roundedFanButtonAbsolute_passive
                                                    }
                                                    onClick={() => { handleOnChange({ device, speed: item.value }); modeSetClose(device) }}
                                                >
                                                    <span>{item.value}</span>
                                                </div>)}
                                            </div>)
                                            : ('-')
                                            // (
                                            //     device.deviceType === 'WATER_LEVEL_CONTROLLER'
                                            //     ?
                                            //     ( <div 
                                            //         style={{ 
                                            //             position: 'absolute', 
                                            //             boxShadow: '1px 1px 10px -5px grey', 
                                            //             width: '70px',
                                            //             display: 'flex',
                                            //             flexDirection:'column',
                                            //             gap: '20px'
                                            //         }}>
                                            //             <div
                                            //                 style={data?.data?.control?.action?.[device.applianceId]?.id == 1 ? motorButtonSelected: motorButton }
                                            //                 onClick={() => {
                                            //                     data?.data?.control?.action?.[device.applianceId]?.value === 0
                                            //                         ? handleOffChange({ device, mode: 'OFF', wlcmotor: 1 })
                                            //                         : handleOnChange({ device, mode: 'ON', wlcmotor: 1 })
                                            //                     modeSetClose(device);
                                            //                 }}
                                            //             >Motor 1</div>
                                            //             <div
                                            //                 style={data?.data?.control?.action?.[device.applianceId]?.id == 2 ? motorButtonSelected: motorButton}
                                            //                 onClick={() =>{
                                            //                     data?.data?.control?.action?.[device.applianceId]?.value === 0
                                            //                         ? handleOffChange({ device, mode: 'OFF', wlcmotor: 2 })
                                            //                         : handleOnChange({ device, mode: 'ON', wlcmotor: 2 })
                                            //                         modeSetClose(device);
                                            //                 }

                                            //                 }
                                            //             >Motor 2</div>
                                            //     </div>)
                                            //     :('null')
                                            // )
                                        }
                                    </Overlay>
                                }
                            </div>

                                {/*  on off button */}
                            <div className="dropdown1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                {
                                    [...data?.data?.control?.on].includes(device?.applianceId)
                                        ? <div className='select-devices-btns' style={{ background: ThemeColor.alertSuccess }} >ON</div>
                                        : [...data?.data?.control?.off].includes(device?.applianceId)
                                            ? <div className='select-devices-btns' style={{ background: ThemeColor.alertFailure }} >OFF</div>
                                            : <div className='select-devices-btns' style={{ background: '#e6e6e6' }} >Default</div>
                                }

                                {/* ON/OFF dropdown */}
                                <div className="dropdown-content">
                                    <p style={{ cursor: 'pointer' }} onClick={() => handleDefaultChange(device)}>Default</p>
                                    <p className='cursor-pointer' style={[...data?.data?.control?.on].includes(device?.applianceId) ? { color: ThemeColor.on, fontWeight: 'bolder' } : { color: 'black' }} onClick={() => handleOnChange({ 
                                        device, 
                                        wlcmotor: data?.data?.control?.action?.[device.applianceId]?.id ?? null
                                    })}>On</p>
                                    <p className='cursor-pointer' style={[...data?.data?.control?.off].includes(device?.applianceId) ? { color: ThemeColor.off, fontWeight: 'bolder' } : { color: 'black' }} onClick={() => handleOffChange({
                                         device, 
                                         wlcmotor: data?.data?.control?.action?.[device.applianceId]?.id ?? null,
                                    })}>Off</p>
                                </div>
                            </div>

                        </div>)}
                    </div>
                    : <CircleBarSpinner />
            }
        </>
    )
}
const roundedFanButton = {
    background: ThemeColor.pirPrimary,
    padding: '10px',
    height: '30px',
    width: '30px',
    textAlign: 'center',
    color: '#ffffff',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}
const roundedFanButtonAbsolute_active = {
    background: ThemeColor.pirPrimary,
    color: ThemeColor.white,
    padding: '10px',
    height: '30px',
    width: '30px',
    textAlign: 'center',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 25
}
const roundedFanButtonAbsolute_passive = {
    background: ThemeColor.white,
    color: ThemeColor.black,
    padding: '10px',
    height: '30px',
    width: '30px',
    textAlign: 'center',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 25
}

const motorButtonSelected = { fontSize: '1.1em', color: 'white', color: ThemeColor.pirPrimary }
const motorButton = { padding: '5px', color: ThemeColor.grey, borderRadius: '4px', textAlign: 'center', background: 'white' }
export default SelectDevices