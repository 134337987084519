import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import { MdOutlet,MdCurtains } from "react-icons/md";
import DeviceSpecific from "../../assets/theme/DeviceSpecificColor";
import "../../assets/styles/main.css";
import "../../assets/styles/responsive.css";
import ThemeColor from "../../assets/theme/ThemeColor";
import { UserService } from "../../services/UserService";
import { User } from "../../storage/store";
import { FaFan, FaLock } from "react-icons/fa";
import { IoLockClosed, IoLockOpen, IoBulbOutline, IoWaterOutline, IoPowerOutline } from "react-icons/io5";
import TimeAgo from "../../pages/helpers.js/TimeAgo";
import { useUser } from "../../Context/context";

const SwitchCard = ({ props }) => {
  const devices = props;
  const navigate = useHistory();
  const PropertyId = User.getPropertyId();
  const [isHovering, setIsHovering] = useState(false);
  const [buttonColor, setButtonColor] = useState("white");
  const [textColor, setTextColor] = useState();
  const [iconColor, setIconColor] = useState();
  const [isHolding, setIsHolding] = useState(false);

  let holdTimer;

  const handleMouseDown = () => {
    holdTimer = setTimeout(() => {
      setIsHolding(true);
      navigate.push('switchDetail', { data: devices, propertyId: PropertyId })
      // console.log("On hold!");
    }, 1000);
  };

  const handleMouseUp = () => {
    clearTimeout(holdTimer);
    setIsHolding(false);
  };

  const handlemouseEnter = () => {
    setIsHovering(true);
  };

  const handlemouseLeave = () => {
    setIsHovering(false);
  };

  const header = {
    left: '15px',
    top: '15px',
    background: buttonColor,
    width: "150px",
    height: "180px",
    margin: "10px",
    borderRadius: "20px",
    boxSizing: "borderBox",
    boxShadow: isHovering ? "0px 0px 10px 1px #0006" : "0px 0px 5px 1px #0002",
    border: `1.7px solid transparent`,
    cursor: "pointer",
    position: "relative",
    transition: "1000ms",
    AnimationDuration: "3s",
    AnimationIterationCount: "1",
    AnimationDirection: "reverse",
    appearance: "auto",
    AnimationName: "bounce",
    transition: "box-shadow .20s ease-in-out",
    transition: "background-position 0.5s ease-in-out",
  };

  async function toggleSwitch(devices) {
    if (devices.deviceType == "SMOKE") {
      if (devices.props.state == false) {
        devices.props.state = true;
        setButtonColor(
          DeviceSpecific.getSwitchColor(devices.deviceType.toLowerCase(), true)
        );
        setTextColor(ThemeColor.switchTextColor);
        setIconColor(ThemeColor.switchTextColor);
      } else {
        setButtonColor("white");
        setTextColor("black");
        setIconColor("black");
        devices.props.state = false;
      }
    } else {
      if (devices.props.state == false) {
        devices.props.state = true;
        setButtonColor(
          DeviceSpecific.getSwitchColor(
            devices.deviceType.toLowerCase(),
            true
          )[1]
        );
        setTextColor(ThemeColor.switchTextColor);
        setIconColor(ThemeColor.switchTextColor);
      } else {
        setButtonColor("white");
        setTextColor("black");
        setIconColor("black");
        devices.props.state = false;
      }
    }
    let payload;
    if (devices.props.state) {
      if (devices.supportedDeviceTraits?.includes('101')) {
        payload = {
          on: [`${devices.applianceId}`],
          off: [],
          action: {
            [devices.applianceId]: {
              id: Number(devices.pin),
              trait: 101,
              value: Number(devices.props.state),
            },
          },
        };
      } else {
        if(devices.deviceType.toLowerCase()=='fan'){
          if (devices.meta.model === 'W1F6SM_1') {
            payload = {
              on: [`${devices.applianceId}`],
              off: [],
              action: {
                [devices.applianceId]: {
                  id: Number(devices.pin),
                  trait: 201,
                  value: Number(devices.props.speed) ?? 6,
                }
              }
            };
          } else {
            payload = {
              on: [`${devices.applianceId}`],
              off: [],
              speeds: {
                [devices.applianceId]: 6,
              },
            };
          }
        }else{
          payload = {
            on: [`${devices.applianceId}`],
            off: [],
            switch: {
              [devices.applianceId]: {
                id: Number(devices.pin),
                status: Number(devices.props.state),
              },
            },
          };
        }
      }
    } else {
      if (devices.supportedDeviceTraits?.includes('101')) {
        payload = {
          on: [`${devices.applianceId}`],
          off: [],
          action: {
            [devices.applianceId]: {
              id: Number(devices.pin),
              trait: 101,
              value: Number(devices.props.state),
            },
          },
        };
      } else {
        payload = {
          on: [],
          off: [`${devices.applianceId}`],
          switch: {
            [devices.applianceId]: {
              id: Number(devices.pin),
              status: Number(devices.props.state),
            },
          },
        };
      }
    }
    //console.log(devices);
    //console.log(devices.states);
    //console.log(navigate.location);
    //console.log(devices.props.state);
    try {
      const PropertyId = User.getPropertyId();
      // console.log("Payload...: " + JSON.stringify(payload));
      const response = await UserService.stateUpdate(payload, PropertyId);
      console.warn(response);
    } catch (error) {
      console.warn(error);
    }
  }

  useEffect(() => {
    if (devices) {
      if (devices.props.state === true) {
        setButtonColor(
          DeviceSpecific.getSwitchColor(
            devices.deviceType.toLowerCase(),
            devices.props.state
          )[1]
        );
        setTextColor(ThemeColor.switchTextColor);
        setIconColor(ThemeColor.switchTextColor);
      }
    }
  }, []);

  const [doorStatus, setDoorStatus] = useState(devices.props.state);

  useEffect(() => {
    setDoorStatus(devices.props.state);
  }, [devices.props.state]);
  return (
    <div
      style={header}
      className="button"
      onClick={() => toggleSwitch(devices)}
      onMouseEnter={handlemouseEnter}
      onMouseLeave={handlemouseLeave}
      // onMouseDown={() => {setIsHolding(true); handlemouseEnter()}}
      // onMouseUp={() => {setIsHolding(false);handlemouseLeave()}}
      onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}
    >
      <div className="switch-container-icon">
        {devices ? (
          devices.deviceType === "SWITCH" ? (
            <IoPowerOutline style={{ color: iconColor }} />
          ) : devices.deviceType === "LIGHT" ? (
            <IoBulbOutline style={{ color: iconColor }} />
          ) : devices.deviceType === "FAN" ? (
            <FaFan style={{ color: iconColor }} />
          ) : devices.deviceType === "SOCKET" ? (
            <MdOutlet style={{ color: iconColor }} />
          ) : devices.deviceType === "DW_SENSOR" ? (
            <div>
              {doorStatus ? (
                <IoLockClosed style={{ color: iconColor }} />
              ) : (
                <IoLockOpen style={{ color: iconColor }} />
              )}
            </div>
          ) : (
            <MdOutlet style={{ color: iconColor }} />
          )
        ) : null}
      </div>
      <div
        // to={{
        //   // pathname: "./switchDetail",
        //   state: { data: devices, propertyId: PropertyId },
        // }}
        style={{ color: "black" }}
      >
        <div
          style={{
            display: 'flex',
            color: textColor,
            marginLeft: "17px",
            height: '40px',
            alignItems: 'start',
            justifyContent: 'start',
            marginBottom: '10px',
            marginTop: '15px',
            overflow: 'hidden',
          }}
        >
          <b style={{ fontSize: '1em', alignSelf: 'end' }}>{devices.name}</b></div>
        <div
          style={{
            color: textColor,
            marginLeft: "17px",
            fontSize: '0.7em',
            height: '15px',
            overflow: 'hidden',
          }}
        >
          <div>{devices.floor}&nbsp;|&nbsp;{devices.room}</div>
        </div>
        <div
          className="sensor-container-device-details"
          style={{
            color: textColor,
            textAlign: "start",
            marginLeft: "17px",
            overflowY: 'hidden',
            padding: '10px 0px 0px 0px',
            fontSize: '0.8em',
          }}
        >
          <div>{TimeAgo.getTimeAgo(devices.props.timeStamp)}</div>
        </div>

      </div>
    </div>
  );
};
export default SwitchCard;
