import { Row, Col, Card, Descriptions, Avatar, Switch } from "antd";

import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
} from "@ant-design/icons";

import Banner from "../../assets/images/Banner.jpg";
import BgProfile from "../../assets/images/Contact.jpeg";
import Contact from "../../assets/images/Contact.png";
import logo from "../../assets/images/logo.png";
import { useEffect, useState } from "react";
import { User } from "../../storage/store";
import { UserService } from "../../services/UserService";
import Main from "../../components/layout/Main";

function Profile() {
  const [name, setName] = useState("");


  useEffect(() => {
    async function getname() {
      try {
        const res = await UserService.getProperty();
        if (res) {
          const name = res[0].property.name;
          setName(name);
        }
      } catch (error) {
        // console.log("....error");
      }
    }
    getname();
  }, []);

  return (
    <>
    <Main>
      <div
        className="profile-nav-bg"
        style={{
          backgroundImage: `url(${Banner})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: "100px",
          borderRadius: "12px",
          marginTop: "40px",
        }}
      ></div>

      <Card
        style={{ border: "white", marginTop: "20px" }}
        className="card-profile-head"
        bodyStyle={{ display: "none" }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={12} className="col-info">
              <Avatar.Group>
                <Avatar
                  size={74}
                  shape="round"
                  style={{ borderRadius: "50px" }}
                  src={BgProfile}
                />

                <div className="avatar-info">
                  <h4 className="font-semibold m-0">
                    {name ? name : "HashH Automations"}
                  </h4>
                </div>
              </Avatar.Group>
            </Col>
            <Col
              span={24}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            ></Col>
          </Row>
        }
      ></Card>

      <Row gutter={[24, 0]} style={{ marginTop: "50px" }}>
        <Col span={24} md={8} className="mb-24 ">
          <Card
            bordered={false}
            className="header-solid h-full"
            title={<h6 className="font-semibold m-0">Platform Settings</h6>}
          >
            <ul className="list settings-list">
              <li>
                <h6 className="list-header text-sm text-muted">ACCOUNT</h6>
              </li>
              <li>
                <Switch defaultChecked />

                <span>Email me when someone follows me</span>
              </li>
              <li>
                <Switch />
                <span>Email me when someone answers me</span>
              </li>
              <li>
                <Switch defaultChecked />
                <span>Email me when someone mentions me</span>
              </li>
              <li>
                <h6 className="list-header text-sm text-muted m-0">
                  APPLICATION
                </h6>
              </li>
              <li>
                <Switch defaultChecked />
                <span>New launches and projects</span>
              </li>
              <li>
                <Switch defaultChecked />
                <span>Monthly product updates</span>
              </li>
              <li>
                <Switch defaultChecked />
                <span>Subscribe to newsletter</span>
              </li>
            </ul>
          </Card>
        </Col>
        <Col span={24} md={8} className="mb-24">
          <Card
            bordered={false}
            title={<h6 className="font-semibold m-0"> About Us</h6>}
            className="header-solid h-full card-profile-information"
            bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
          >
            <p className="text-dark">
              {" "}
              <b>
                We Build Awesome Products That Makes Your Lives Easier & Simpler
              </b>
              <br />
              Our smart home products are equipped with detailed statistics and
              analytics, allowing you to track your energy usage, monitor your
              home’s security, and more. With our intuitive mobile app, you can
              access real-time data on your home’s performance, so you can make
              informed decisions about how to optimize your home.
            </p>
            <hr className="my-25" />
            <img src={logo} height="20px" width="20px" />
            <span
              style={{
                fontWeight: "bold",
                marginLeft: "5px",
                marginTop: "13px",
              }}
            >
              HASHh Automations
            </span>

            <Descriptions style={{ marginTop: "20px" }}>
              <Descriptions.Item label="Email" span={3}>
                hello@hashh.io
              </Descriptions.Item>
              <Descriptions.Item label="Location" span={3}>
                Pudupalayam, Udumalpet, T K 4/109,
                <br />
                Ravanapuram, Tamil Nadu 642205
              </Descriptions.Item>
              <Descriptions.Item label="Social" span={3}>
                <a href="#pablo" className="mx-5 px-5">
                  {<TwitterOutlined />}
                </a>
                <a href="#pablo" className="mx-5 px-5">
                  {<FacebookOutlined style={{ color: "#344e86" }} />}
                </a>
                <a href="#pablo" className="mx-5 px-5">
                  {<InstagramOutlined style={{ color: "#e1306c" }} />}
                </a>
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col span={24} md={8} className="mb-24">
          <Card
            bordered={false}
            title={<h6 className="font-semibold m-0">For Service Contact</h6>}
            className="header-solid h-full"
            bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
          >
            <img
              src={Contact}
              width="280px"
              height="280px"
              style={{ marginLeft: "50px" }}
            />
            <p style={{ textAlign: "center" }}>
              Interested in Hashh Automations? <br />
              Just pick up the phone to chat with a member of our sales team.
            </p>
            <h6
              style={{
                textAlign: "center",
                fontFamily: "monospace",
              }}
            >
              +919944935374
            </h6>
          </Card>
        </Col>
      </Row>
      </Main>
    </>
  );
}

export default Profile;
