import axios from "axios";
import { Keycloaktokens } from "../storage/store";

/**
 * Create an Axios Client with defaults
 */
const axiosClient = axios.create({
  baseURL: "https://api.hashh.io/",
  timeout: 30000,
});

// let initOptions = {
//     url: 'https://auth.hashh.io/auth/',
//     realm: 'HASHh',
//     clientId: 'hashh-dashboard',
//     onLoad: 'check-sso',
//     KeycloakResponseType: 'code',
//     scopes: ['openid', 'profile'],
// }

// const keycloak = new Keycloak(initOptions);

// keycloak.init({ onLoad: initOptions.onLoad, KeycloakResponseType: 'code', checkLoginIframe: false }).then((auth) => {
//     if (!auth) {
//         //window.location.reload();
//         console.info("Authenticated Failed");
//     } else {
//         console.info("Authenticated");
//         console.log(keycloak.token);
//         //navigate.push ('SelectEcoSystem');
//     }
// }, () => {
//     console.error("Authenticated Failed");
// });

// const keycloak = Keycloak.init({
//     onLoad: 'check-sso',
// });
/**
 * Request Wrapper with default success/error actions
 */
const Api = async (
  config = { method: "GET", data: {}, url: "" },
  shouldAppendToken = true
) => {
  // Success
  const onSuccess = (response) => {
    // console.log("response", JSON.stringify(response.data, null, 2));
    return Promise.resolve(response.data);
  };

  // Error
  const onError = (error) => {
    // console.log("Request Failed:", error);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      // console.log("Data:", error.response.data);
      return Promise.reject(error.response.data);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      // console.log("Error Message:", error.message);
      return Promise.reject(error.message);
    }
  };

  // Append headers
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  // Append auth token
  if (shouldAppendToken) {
    try {
      const token = Keycloaktokens.getToken();
      headers["Authorization"] = `Bearer ${token}`;
      // console.log(token);
    } catch (error) {
      // Token is not found
      // console.log("Error Message:", error.message);

      return Promise.reject(error.message);
    }
  }

  // Set headers
  axiosClient.defaults.headers = headers;

  // console.log("Request Headers!", axiosClient.defaults.headers);
  // console.log("Request Configurations!", config);

  return axiosClient(config).then(onSuccess).catch(onError);
};

export default Api;
