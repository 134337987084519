import { useState, useEffect } from "react";
import Main from '../../../components/layout/Main'
import add_files from "../../../assets/images/add_files.svg";
import ThemeColor from '../../../assets/theme/ThemeColor';
import BottomSheet from "./BottomSheet"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import RoutineCard from "./RoutineCard";
import { MdDelete, MdAdd } from "react-icons/md";
import { UserService } from "../../../services/UserService";
import { User } from "../../../storage/store";
import moment from "moment";
import ToggleTop from "../../../components/common/ToggleTop";


const Routines = () => {
  const [openSheet, setOpenSheet] = useState(false);
  const [mapData, setMapData] = useState([]);
  const [isDelete, setIsDelete] = useState(false);
  const PropertyId = User.getPropertyId();
  const [updateMsg, setUpdateMsg] = useState('none');

  const showDrawer = () => {
    setOpenSheet(true);
  };

  const routineDelete = () => {
    setIsDelete(!isDelete)
  };

  const routineSetter = async () => {
    const result = await UserService.getRoutine(PropertyId);
    setMapData([]);
    result.forEach((item, index) => {
      let time = item.data.time.split(':');
      time = `${time[0]}:${time[1]}`;
      item.data.time = time;
    })
    setMapData([...result]);
  }

  const deleteRoutineCard = async (id, name) => {
    if (window.confirm(`Are you sure you want to delete routine '${name}' ?`) !== false) {
      setUpdateMsg('green');
      // setTimeout(() => { setUpdateMsg('none') }, 3000)
      try {
        const result = await UserService.deleteRoutine(id);
        routineSetter();
        setIsDelete(false);
      } catch (e) {
        // console.log(e);
      }
    } else  {
      setUpdateMsg('red');
      // setTimeout(() => { setUpdateMsg('none') }, 3000);
      setIsDelete(false);
    }
  }

  const handleRoutineStatus = async (id, status) => {
    try {
      const result = await UserService.enableRoutine(id, { disable: !status });
    } catch (e) {
      // console.log(e);
    }
  }

  const closeDrawer = () => setOpenSheet(false);
  useEffect(() => {
    routineSetter();
  }, [openSheet])

  return (
    <>
      {updateMsg === 'green' ? <ToggleTop color='green' message="Routine deleted successfully" toggle={setUpdateMsg}/>
        : updateMsg === 'red' ? <ToggleTop color='red' message="Routine not deleted" toggle={setUpdateMsg}/>
          : null
      }
      <Main>
        <div style={styles.container} >
          {mapData.length ? (
            <div>
              <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', }}>
                <div className="create-routine" style={{ cursor: 'pointer', backgroundColor: ThemeColor.darkgreen, borderRadius: '5px', alignItems: 'center', display: 'flex' }}>
                  <MdAdd style={{ color: ThemeColor.white, fontSize: 20, marginLeft: '5px' }} />
                  <button
                    type='button'
                    style={{ ...styles.button, background: ThemeColor.darkgreen, }}
                    onClick={showDrawer}
                    disabled={isDelete}
                  >
                    Add Routine
                  </button>
                </div>
                <div onClick={routineDelete} className="create-routine" style={{ cursor: 'pointer', backgroundColor: isDelete ? ThemeColor.lightgrey : ThemeColor.darkred, borderRadius: '5px', alignItems: 'center', display: 'flex' }}>
                  <MdDelete style={{ color: isDelete ? ThemeColor.black : ThemeColor.white, fontSize: 20, marginLeft: '5px' }} />
                  <button
                    type='button'
                    style={{ ...styles.button, background: isDelete ? ThemeColor.lightgrey : ThemeColor.darkred, color: isDelete ? ThemeColor.black : ThemeColor.white }}
                    onClick={routineDelete}
                  >
                    {isDelete ? 'Cancel' : 'Delete Routine '}
                  </button>
                </div>
              </div>
              <hr style={{ marginTop: '20px' }} />
            </div>
          ) : null}
          {mapData.length ? (
            <div style={{ overflowX: 'scroll', }}>
              <div style={{
                display: 'flex',
                gap: '40px',
                padding: '20px'
              }}>
                {[...mapData].length ? (
                  [...mapData].map((item, index) => (
                    <RoutineCard
                      key={item._id}
                      index={index}
                      data={{ ...item }}
                      deleteRoutineCard={deleteRoutineCard}
                      isDelete={isDelete}
                      handleRoutineStatus={handleRoutineStatus}
                      bgColor={ThemeColor.quickActions[index % 9]}
                      updateMsg={updateMsg}
                    />
                  ))
                ) : null
                }
              </div>
            </div>
          ) : (
            <div>
              <div style={styles.imageContainer}>
                <img className="add-data-img" onClick={showDrawer} src={add_files} style={{}} />
              </div>
              <div style={styles.buttonContainer}>
                <button
                  type='button'
                  style={{ ...styles.button, background: ThemeColor.hashhPrimary, }}
                  onClick={showDrawer}
                  className="create-routine"
                >
                  Create Routine
                </button>
              </div>
            </div>
          )}
          {openSheet ? (
            <BottomSheet closeDrawer={closeDrawer} routineSetter={routineSetter} />
          ) : null}
        </div>
      </Main>
    </>

  )
}

const styles = {
  container: {
    backgroundColor: '#fff',
    padding: '15px',
    borderRadius: '10px',
    height: '100%'
  },
  buttonContainer: {
    textAlign: 'center',
    marginTop: '10%'
  },
  imageContainer: {
    textAlign: 'center',
    marginTop: '7%'
  },
  button: {
    cursor: 'pointer',
    // background: ThemeColor.hashhPrimary,
    border: 'none', color: ThemeColor.white,
    padding: '10px',
    textAlign: 'center',
    borderRadius: '10px',
  },
}

export default Routines