import React, { useEffect, useState } from 'react'
import CircleBarSpinner from '../common/CircleBarSpinner';
import { useUser } from '../../Context/context';
import { User } from '../../storage/store';
import { UserService } from '../../services/UserService';

const Fan = ({}) => {
    // const [speed, setSpeed] = useState();
    const {device, setDevice} = useUser();
    const regulatorClass = [
        `fan-regulatorClass-one`,
        `fan-regulatorClass-two`,
        `fan-regulatorClass-three`,
        `fan-regulatorClass-four`,
        `fan-regulatorClass-five`,
        `fan-regulatorClass-six`
    ]
    const speeds = [
        {
            value: 1,
            classActive: `switchDetail-fanSpeed speed-one speed-active`,
            classPassive: `switchDetail-fanSpeed speed-one`,
        },
        {
            value: 2,
            classActive: `switchDetail-fanSpeed speed-two speed-active`,
            classPassive: `switchDetail-fanSpeed speed-two`,
        },
        {
            value: 3,
            classActive: `switchDetail-fanSpeed speed-three speed-active`,
            classPassive: `switchDetail-fanSpeed speed-three`,
        },
        {
            value: 4,
            classActive: `switchDetail-fanSpeed speed-four speed-active`,
            classPassive: `switchDetail-fanSpeed speed-four`,
        },
        {
            value: 5,
            classActive: `switchDetail-fanSpeed speed-five speed-active`,
            classPassive: `switchDetail-fanSpeed speed-five`,
        },
        {
            value: 6,
            classActive: `switchDetail-fanSpeed speed-six speed-active`,
            classPassive: `switchDetail-fanSpeed speed-six`,
        },
    ]
    const handleFanSpeed=async(speed)=>{
        let payload;
        if (device.meta.model === 'W1F6SM_1') {
            payload = {
              on: [`${device.applianceId}`],
              off: [],
              action: {
                [device.applianceId]: {
                  id: Number(device.pin),
                  trait: 201,
                  value: speed ?? 3,
                }
              }
            };
          } else {
            payload = {
              on: [`${device.applianceId}`],
              off: [],
              speeds: {
                [device.applianceId]: speed ??  6,
              },
            };
          }
          try {
            const PropertyId = User.getPropertyId();
            // console.log("Payload...: " + JSON.stringify(payload));
            const response = await UserService.stateUpdate(payload, PropertyId);
            console.warn(response);
            // setTrigger(!trigger);
          } catch (error) {
            console.warn(error);
          }
    }

    useEffect(() => {
       if(device.props?.speed>=0){
        // console.log(device.props.speed);
       }else{
        device.props.speed=3;
       }
    }, [])

    // console.log(device.props.speed);
    return (
        (device)
            ? <div className='switchDetail-fan-container' >
                <div className='switchDetail-fan-container-sec' >

                    {speeds.map((item, index) => <div
                        key={`fan${index}`}
                        className={device.props.speed == item.value ? item.classActive : item.classPassive}
                        onClick={() => {
                            device.props.speed=item.value
                            setDevice({...device});
                            handleFanSpeed(item.value);
                        }}
                    >{item.value}</div>)}

                    <div className='speed-fan'>
                        <div className={
                            `fan-regulator ${regulatorClass[Number(device.props.speed - 1)]}`
                        }>
                            <span className='fan-regulator-direction'></span>
                        </div>
                    </div>
                </div>
            </div>
            : <CircleBarSpinner/>)
        }

export default Fan