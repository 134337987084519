import React, { useState, useEffect } from "react";
import {
  Chart,
  Series,
  CommonSeriesSettings,
  Label,
  Format,
  Legend,
  Export,
} from "devextreme-react/chart";
import { UserService } from "../../../services/UserService";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import { Card } from "antd";
import { Column } from '@ant-design/plots';
import { useToggleTop } from "../../common/ToggleTop";

function DayChart() {
  const [data, setData] = useState([]);
  const params = useLocation();
  const device = params?.state?.data;

  const setToggleTop = useToggleTop();

  const [changeDate, setChangeDate] = useState(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const formattedDate = `${year}-${month}`;
    return formattedDate;
  });
  const fetchData = async () => {
    try {
      const res = await UserService.getDoorStatusForMonth(
        device.applianceId,
        changeDate
      );
      const result = res.map((item, index) => {
        const open = {
          date: moment(item.bucket).format("MM-DD"),
          // hour: moment(item.bucket).utc().hours(),
          type: 'open-count',
          count: parseInt(item.open_count)
        }
        const close = {
          date: moment(item.bucket).format("MM-DD"),
          // hour: moment(item.bucket).utc().hours(),
          type: 'close-count',
          count: parseInt(item.close_count)
        }
        return [open, close];
      }).reduce((acc, current)=>{
        return [...acc, ...current]
      },[])

      setData(result);
    } catch (error) {
      setToggleTop("Error fetching Data !", 'red');
      // console.log("MonthlyChartError", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [changeDate]);

  const config = {
    data,
    xField: 'date',//month- date
    yField: 'count',//value- count
    colorField: 'type',//name- type
    group: true,
    scrollbar: {
      x: {}
    },
    style: {
      inset: 5,
      // insetLeft:5,
      // insetRight:20,
      // insetBottom:10
      // insetTop:10
    },
  };
  
  return (
    <Column {...config} />
  );
}
export default DayChart;
