import { Background } from 'devextreme-react/cjs/range-selector'
import React, { useEffect, useState } from 'react'
import ThemeColor from '../../../assets/theme/ThemeColor'
import Hr from '../../../components/common/Hr'
import SideDrawer from '../../../components/common/SideDrawer'
import SelectDevices from '../../../components/common/SelectDevices'
import { UserService } from '../../../services/UserService'
import { User } from '../../../storage/store'
import { useToggleTop } from '../../../components/common/ToggleTop'
import DoubleTapIcon from '../../../components/icons/DoubleTapIcon'
import BackgroundCard from '../../../components/layout/BackgroundCard'
import CircleBarSpinner from '../../../components/common/CircleBarSpinner'
import { off, on } from 'devextreme/events'
import RadioButton from '../../../components/common/RadioButton'
import { ArrowDownOutlined } from '@ant-design/icons'
import { FaChevronDown } from 'react-icons/fa'
import Scenes from './Scenes'
import { IoSunny } from 'react-icons/io5'

const SceneDetail = ({ data, title, setBottomSheet, getScenes, sceneType }) => {

    data = data ?? {}
    const [scene, setScene] = useState(null);
    const [sideDrawer, setSideDrawer] = useState('');
    const [eventTiming, setEventTiming] = useState(false)
    const setToggleTop = useToggleTop();

    useEffect(() => {
        
        if (Object.keys(data).length && title === 'update') {
            let _d = {
                name: data?.name,
                data: data?.data,
                sceneType: data?.sceneType,
                sunRiseSunSetConfig: {
                    eventType: data?.sunRiseSunSetConfig?.eventType,
                    eventTiming: Number(data?.sunRiseSunSetConfig?.eventTiming),
                    daysOfWeek: [...data?.sunRiseSunSetConfig?.daysOfWeek]
                }
            }
            setScene(_d);
        } else {
            let _d = {
                name: "",
                data: {
                    control: {
                        on: [],
                        off: [],
                        action: {}
                    },
                },
                sceneType: data?.sceneType,
                sunRiseSunSetConfig: {
                    eventType: 'sunrise',
                    eventTiming: 0,
                    daysOfWeek: []
                }
            }

            setScene(_d);
        }

    }, []);

    const addScene=async()=>{
        
        if(!scene.name){
            setToggleTop('Add Scene name', ThemeColor.pirSecondary);
            return 0;
        }
        
        else if(![...scene?.data?.control?.on, ...scene?.data?.control?.off].length){
            setToggleTop('Must select atleast one Device', ThemeColor.pirSecondary);
            return 0;
        }
        else if(scene.sceneType==='sunRiseSunSet' && !scene?.sunRiseSunSetConfig?.daysOfWeek.length)
            setToggleTop('Select atlest one day', ThemeColor.pirSecondary);
        else{
            const PropertyId = User.getPropertyId();
            try{
                const addScene = await UserService.addScene(scene, PropertyId);
                setToggleTop('Scene Added Sucessfully', 'green');
                setBottomSheet('close');
                getScenes();
            }catch(e){
                setToggleTop('Error Adding Scene', 'red');
            }finally{
                setBottomSheet('close'); 
                setScene(null)
            }
        }
        
        
    }

    const updateScene = async () => {
        const propertyId = User.getPropertyId();
        // console.log(scene)
        // console.log(propertyId)
        // console.log(data?._id);
        try {
            const SceneResponse = await UserService.updateScene(scene, propertyId, data._id);
            // console.log(SceneResponse);
            setToggleTop('Scene Updated', 'green');
        } catch (e) {
            // console.log(e);
            setToggleTop('Error Updating Scene', 'red');
        }finally{
            setBottomSheet('close'); 
            setScene(null)
        }
        // console.log(scene)
    }
    const handleNameChange = (name) => {
        scene.name = name;
        setScene({ ...scene });
    }

    const handleAction = ({ device, mode, speed, dimValue, motor, wlcmotor, wgcmotor }) => {
        return new Promise((resolve, reject) => {
            let action;
            switch (mode) {

                case 'ON':
                    if (device.deviceType === "FAN") {
                        if (device.meta.model === 'W1F6SM_1') {
                            action = {
                                id: Number(device.pin),
                                trait: 201,
                                value: speed,
                            }
                        } else {
                            action = {
                                speed
                            }
                        }
                    } else if (device.meta.model === 'W1SM_1' || device.meta.model === 'W2SM_1' || device.meta.model === 'W3SM_1' || device.meta.model === 'W4SM_1' || device.meta.model === 'W1TSM_1' || device.meta.model === 'W2TSM_1' || device.meta.model === 'W3TSM_1' || device.meta.model === 'W4TSM_1' || device.meta.model === 'W10SM_1' || device.meta.model === 'W16SM_1' || device.meta.model === 'W1F6SM_1' || device.meta.model === 'W1SP_1') {
                        action = {
                            id: Number(device.pin),
                            trait: 101,
                            value: 1
                        }
                    } else if (device.deviceType === 'DIMMER_MODULE') {
                        action = {
                            id: ('pin' in device) ? Number(device.pin) : 1,
                            trait: 1003,
                            value: dimValue,
                        };
                    } else if (device.deviceType === 'CURTAIN_MODULE') {
                        if (device.meta.model === 'W1CM_1') {
                            action = {
                                id: 1,
                                trait: 901,
                                value: 0,
                            };
                        } else if (device.meta.model === 'W2CM_1') {
                            action = {
                                id: motor,
                                trait: 901,
                                value: 1
                            }
                        }
                    } else if (device.deviceType === 'WATER_LEVEL_CONTROLLER') {
                        action = {
                            id: wlcmotor,
                            trait: 701,
                            value: 1,
                        };
                    } else if (device.meta.model === "GC_1") {
                        if (device.deviceType === "SWING_GATE") {
                            action = {
                                action: 0,
                                rl: device?.pin ?? 1
                            }
                        } else {
                            action = {
                                action: 0,
                                rl: 1
                            }
                        }
                    } else if (device.meta.model === "WGC_1") {
                        if (device.deviceType === "SWING_GATE") {
                            action = {
                                id: wgcmotor,
                                trait: 601,
                                value: 1
                            }
                        } else {
                            action = {
                                id: 1,
                                trait: 601,
                                value: 1
                            }
                        }
                    } else if (device.meta.model === 'WPCS_1') {
                        action = {
                            id: 1,
                            trait: 401,
                            value: 1,
                        };
                    } else if (device.meta.model === 'ISDW_1') {
                        action = {
                            device: 'ISDW_1',
                            action: 'arm',
                        };
                    } else if (device.deviceType === 'ROBOT_VACUUM_CLEANER') {
                        action = {
                            id: 1,
                            trait: 1103,
                            value: 1,
                        };
                    }
                    else {
                        action = {
                            id: ('pin' in device) ? Number(device.pin) : 1,
                            trait: 101,
                            value: 0,
                        };
                    }
                    break;

                case 'OFF':
                    if (device.deviceType === "FAN") {
                        if (device.meta.model === 'W1F6SM_1') {
                            action = {
                                id: Number(device.pin),
                                trait: 201,
                                value: 0,
                            }
                        } else {
                            action = {
                                speed: 0
                            }

                        }
                    } else if (device.meta.model === 'W1SM_1' || device.meta.model === 'W2SM_1' || device.meta.model === 'W3SM_1' || device.meta.model === 'W4SM_1' || device.meta.model === 'W1TSM_1' || device.meta.model === 'W2TSM_1' || device.meta.model === 'W3TSM_1' || device.meta.model === 'W4TSM_1' || device.meta.model === 'W10SM_1' || device.meta.model === 'W16SM_1' || device.meta.model === 'W1F6SM_1' || device.meta.model === 'W1SP_1') {
                        action = {
                            id: Number(device.pin),
                            trait: 101,
                            value: 0
                        }
                    } else if (device.deviceType === 'DIMMER_MODULE') {
                        action = {
                            id: ('pin' in device) ? Number(device.pin) : 1,
                            trait: 1003,
                            value: 0,
                        };
                    } else if (device.deviceType === 'CURTAIN_MODULE') {
                        if (device.meta.model === 'W1CM_1') {
                            action = {
                                id: 1,
                                trait: 901,
                                value: 1,
                            };
                        } else if (device.meta.model === 'W2CM_1') {
                            action = {
                                id: motor,
                                trait: 901,
                                value: 1
                            }
                        }
                    }
                    else if (device.deviceType === 'WATER_LEVEL_CONTROLLER') {
                        action = {
                            id: wlcmotor,
                            trait: 701,
                            value: 0,
                        };
                    } else if (device.meta.model === "GC_1") {
                        if (device.deviceType === "SWING_GATE") {
                            action = {
                                action: 1,
                                rl: device?.pin ?? 1
                            }
                        } else {
                            action = {
                                action: 1,
                                rl: 2
                            }
                        }
                    } else if (device.meta.model === "WGC_1") {
                        if (device.deviceType === "SWING_GATE") {
                            action = {
                                id: wgcmotor,
                                trait: 601,
                                value: 2
                            }
                        } else {
                            action = {
                                id: 2,
                                trait: 601,
                                value: 1
                            }
                        }
                    } else if (device.meta.model === 'WPCS_1') {
                        action = {
                            id: 1,
                            trait: 401,
                            value: 0,
                        };
                    } else if (device.meta.model === 'ISDW_1') {
                        action = {
                            device: 'ISDW_1',
                            action: 'unarm',
                        };
                    } else if (device.deviceType === 'ROBOT_VACUUM_CLEANER') {
                        action = {
                            id: 1,
                            trait: 1103,
                            value: 0,
                        };
                    } else {
                        action = {
                            id: ('pin' in device) ? Number(device.pin) : 1,
                            trait: 101,
                            value: 0,
                        };
                    }
                    break;


                case 'STOP':
                    if (device.deviceType === 'CURTAIN_MODULE') {
                        if (device.meta.model === 'W1CM_1') {
                            action = {
                                id: 1,
                                trait: 901,
                                value: 2,
                            };
                        } else if (device.meta.model === 'W2CM_1') {
                            action = {
                                id: 1,
                                trait: 901,
                                value: 0
                            }
                        }
                        else if (device.meta.model === "GC_1") {
                            if (device.deviceType === "SWING_GATE") {
                                action = {
                                    action: 2,
                                    rl: device?.pin ?? 1
                                }
                            } else {
                                action = {
                                    action: 2,
                                    rl: 3
                                }
                            }
                        }
                    } else if (device.meta.model === "WGC_1") {
                        if (device.deviceType === "SWING_GATE") {
                            action = {
                                id: wgcmotor,
                                trait: 601,
                                value: 3
                            }
                        } else {
                            action = {
                                id: 3,
                                trait: 601,
                                value: 1
                            }
                        }
                    }
                    break;
            }
            resolve(action);
        })
    }

    const handleDefaultChange = (data) => {
        const i_on = scene.data.control.on.indexOf(data.applianceId);
        if (i_on >= 0) {
            scene.data.control.on.splice(i_on, 1);
        }

        const i_off = scene.data.control.off.indexOf(data.applianceId);
        if (i_off >= 0) {
            scene.data.control.off.splice(i_off, 1);
        }
        delete scene.data.control?.action[data.applianceId];
        setScene({ ...scene });
    };

    const handleOnChange = async ({ device, speed, mode, dimValue, motor, wlcmotor, wgcmotor }) => {

        speed = speed ?? 3;
        mode = mode ?? 'ON';
        dimValue = dimValue ?? 100;
        motor = motor ?? 1;
        wlcmotor = wlcmotor ?? 1;
        wgcmotor = wgcmotor ?? 1
        const i_off = scene.data.control.off.indexOf(device.applianceId);
        if (i_off >= 0) {
            scene.data.control.off.splice(i_off, 1);
        }
        let onDevices = [...scene.data.control.on, device.applianceId]

        scene.data.control.on = [...new Set(onDevices)];
        let action = await handleAction({ device, mode, speed, dimValue, motor, wlcmotor, wgcmotor });
        scene.data.control.action[device.applianceId] = { ...action };

        setScene({ ...scene });
        // setPayload({ ...payload });
    };

    const handleOffChange = async ({ device, speed, mode, dimValue, motor, wlcmotor, wgcmotor }) => {
        speed = speed ?? 3;
        mode = mode ?? 'OFF';
        dimValue = dimValue ?? 0;
        motor = motor ?? 2;
        wlcmotor = wlcmotor ?? 1;
        wgcmotor = wgcmotor ?? 1;

        const i_on = scene.data.control.on.indexOf(device.applianceId);
        if (i_on >= 0) {
            scene.data.control.on.splice(i_on, 1);
        }
        let offDevices = [...scene.data.control.off, device.applianceId]
        scene.data.control.off = [...new Set(offDevices)];

        let action = await handleAction({ device, mode, speed, dimValue, motor, wlcmotor, wgcmotor });
        scene.data.control.action[device.applianceId] = { ...action };
        // scene.data = { control: { ...payload } };
        setScene({ ...scene });
        // setPayload({ ...payload });
    };

    const handleEventType = (type) => {
        scene.sunRiseSunSetConfig.eventType = type;
        // console.log(type);
        setScene({ ...scene });
    }

    const handleEventTiming = (eventTime, type) => {
        scene.sunRiseSunSetConfig.eventTiming = Number(eventTime);
        if (type === 'sunrise' || type === 'sunset')
            scene.sunRiseSunSetConfig.eventType = type;
        // console.log(eventTime, type);
        setScene({ ...scene });
    }

    const handleDaysOfWeek = (dow) => {
        // console.log(dow);
        const _dow = [...scene.sunRiseSunSetConfig.daysOfWeek];
        if (_dow.includes(dow.value)) {
            let index = _dow.indexOf(dow.value)
            _dow.splice(index, 1);
        } else {
            _dow.push(dow.value);
        }
        scene.sunRiseSunSetConfig.daysOfWeek = [...new Set(_dow)];
        setScene({ ...scene });
    }

    const _daysOfWeek = [
        { label: 'Mon', value: 'Monday' },
        { label: 'Tue', value: 'Tuesday' },
        { label: 'Wed', value: 'Wednesday' },
        { label: 'Thu', value: 'Thursday' },
        { label: 'Fri', value: 'Friday' },
        { label: 'Sat', value: 'Saturday' },
        { label: 'Sun', value: 'Sunday' },
    ];
    const _eventTimings = {
        '0': { type: 'sunrise', value: 0, displayString: 'Sunrise' },
        '60': { type: 'afterOneHour', value: 60, displayString: 'After 1 Hour' },
        '30': { type: 'afterThirtyMins', value: 30, displayString: 'After 30 Minutes' },
        '10': { type: 'afterTenMins', value: 10, displayString: 'After 10 minutes' },
        '-10': { type: 'beforeTenMins', value: -10, displayString: 'Before 10 Minutes' },
        '-30': { type: 'beforeThirtyMins', value: -30, displayString: 'Before 30 Minutes' },
        '-60': { type: 'beforeOneHour', value: -60, displayString: 'Before 1 Hour' },
        '-0': { type: 'sunset', value: 0, displayString: 'Sunset' },
    }

    // const handleSceneUpdate = async () => {
    //     try {
    //         const propertyId = User.getPropertyId();
    //         const response = await UserService.updateScene(payload, propertyId, data._id);
    //         console.log(response);
    //         getScenes();
    //         setBottomSheet('close');
    //         setToggleTop('Scene updated successfully.', 'green');

    //     }catch(e){
    //         setToggleTop('Problem updating scenes !', 'red');
    //     }
    // }
    // console.log(scene);
    return (
        <>
            {scene ? <>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
                    <div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            background: '#D8B1FF',
                            padding: '20px 20px 60px 20px'
                        }}>
                            <div style={{ cursor: 'pointer' }} onClick={() => { setBottomSheet('close'); setScene(null) }}>Cancel</div>
                            <div><p style={{ fontSize: '1.5em' }}>{title === 'update' ? 'Update Scene' : 'Add Scene'}</p></div>
                            {title === 'update' ? <div style={{ cursor: 'pointer' }} onClick={updateScene}>Update</div> : <div style={{ cursor: 'pointer' }} onClick={addScene}>Add</div>}
                        </div>

                        <div
                            style={{
                                margin: '0 auto',
                                marginTop: '-35px',
                                padding: '20px 5px',
                                background: '#ffffff', bottom: '5px',
                                width: '90%',
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '20px',
                                borderRadius: '12px'
                            }}
                        >
                            {/* <div style={{textAlign:'center', }}> */}
                            {scene?.sceneType === 'tapToStart'
                                ? (
                                    <>
                                    <div>
                                        <DoubleTapIcon style={{
                                            // padding: '10px',
                                            // height: '50px',
                                            // width: '50px',
                                            // background: 'white',
                                            // borderRadius: '50%',
                                            fontSize: '65px',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }} />
                                    </div>
                                    <div>Tap-to-Start</div>
                                    </>
                                )
                                : (scene?.sceneType === 'sunRiseSunSet'
                                    ? (
                                        <>
                                    <div>
                                        <IoSunny style={{
                                            // padding: '10px',
                                            // height: '50px',
                                            // width: '50px',
                                            // background: 'white',
                                            // borderRadius: '50%',
                                            fontSize: '35px',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }} />
                                    </div>
                                    <div>Sunrise-Sunset</div>
                                    </>
                                    )
                                    : 'Unknown'
                                )
                            }
                            {/* <div>
                                <DoubleTapIcon style={{
                                    // padding: '10px',
                                    // height: '50px',
                                    // width: '50px',
                                    // background: 'white',
                                    // borderRadius: '50%',
                                    fontSize: '65px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }} />
                            </div>
                            <div>Tap-to-Start</div> */}
                            {/* </div> */}
                        </div>
                    </div>
                    {/* <Hr /> */}

                    <div style={{ background: 'white', width: '90%', margin: '0 auto', padding: '25px', borderRadius: '12px', display: 'flex', flexDirection: 'column', gap: '30px' }}>

                        {/* scene Name */}
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', }}>
                            <div><strong style={{ fontWeight: 'bolder', fontSize: '1.2em' }}>Scene Name</strong></div>
                            <div>
                                {title === 'update' ?
                                    <input className='switchSettings-input' style={{ width: '100%', color: '#aeb0af', padding: '5px 10px' }} defaultValue={scene?.name} readOnly /> :
                                    <input className='switchSettings-input' style={{ width: '100%', color: 'black', padding: '5px 10px' }} placeholder='Enter Scene name here' onChange={(e) => handleNameChange(e.target.value)} />}
                            </div>
                            <small style={{ color: ThemeColor.pirPrimary }}>Name can't be changed once created</small>
                        </div>

                        {/* sunrise-sunset */}
                        {scene?.sceneType === 'sunRiseSunSet' && <>
                            {/* Event Type */}
                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                <div>
                                    <RadioButton
                                        lable={'Sunrise'}
                                        type={'sunrise'}
                                        name={'srss'}
                                        checked={scene?.sunRiseSunSetConfig?.eventType === 'sunrise'}
                                        onChange={handleEventType}
                                    />
                                </div>
                                <div>
                                    <RadioButton
                                        lable={'Sunset'}
                                        type={'sunset'}
                                        name={'srss'}
                                        checked={scene?.sunRiseSunSetConfig?.eventType === 'sunset'}
                                        onChange={handleEventType}
                                    />
                                </div>
                            </div>


                            {/* eventTiming */}
                            <div style={{ position: 'relative' }}>
                                <div
                                    className='cursor-pointer'
                                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: `1px solid ${ThemeColor.pirPrimary}`, padding: ' 12px 15px', borderRadius: '12px' }}
                                    onClick={() => setEventTiming(!eventTiming)}
                                >
                                    <div style={{ fontSize: '1.2em' }}>{
                                        Number(scene?.sunRiseSunSetConfig?.eventTiming) === 0
                                            ?
                                            (
                                                scene?.sunRiseSunSetConfig?.eventType === 'sunset' ? 'Sunset' : 'Sunrise'
                                            )
                                            :
                                            (
                                                Number(scene?.sunRiseSunSetConfig?.eventTiming) === 60
                                                    ?
                                                    'After 1 Hour'
                                                    :
                                                    (
                                                        Number(scene?.sunRiseSunSetConfig?.eventTiming) === -60
                                                            ?
                                                            'Before 1 Hour'
                                                            :
                                                            (
                                                                Number(scene?.sunRiseSunSetConfig?.eventTiming) === 30
                                                                    ?
                                                                    'After 30 Minutes'
                                                                    :
                                                                    (
                                                                        Number(scene?.sunRiseSunSetConfig?.eventTiming) === -30
                                                                            ?
                                                                            'Before 30 Minutes'
                                                                            :
                                                                            (
                                                                                Number(scene?.sunRiseSunSetConfig?.eventTiming) === 10
                                                                                    ?
                                                                                    'After 10 Minutes'
                                                                                    :
                                                                                    (
                                                                                        Number(scene?.sunRiseSunSetConfig?.eventTiming) === -10
                                                                                            ?
                                                                                            'Before 10 Minutes'
                                                                                            :
                                                                                            ('-')
                                                                                    )
                                                                            )
                                                                    )
                                                            )
                                                    )
                                            )
                                    }</div>
                                    <div><FaChevronDown /></div>
                                </div>

                                {/* eventTiming dropDown*/}
                                {eventTiming && <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        textAlign: 'center',
                                        gap: '10px',
                                        position: 'absolute',
                                        background: 'white',
                                        width: '250px',
                                        alignItems: 'center',
                                        height: '150px',
                                        overflow: 'scroll',
                                        border: `1px solid ${ThemeColor.pirPrimary}`,
                                        top: '60px',
                                        right: 0,
                                        borderRadius: '12px',
                                        minWidth: '200px',
                                        boxSizing: 'content-box'

                                    }}
                                >
                                    <div
                                        style={(scene?.sunRiseSunSetConfig?.eventType === 'sunrise' && Number(scene?.sunRiseSunSetConfig?.eventTiming) === 0) ? ssrs_eventTiming_style_selected : ssrs_eventTiming_style}
                                        onClick={() => handleEventTiming(0, 'sunrise')}
                                    > Sunrise</div>
                                    <div
                                        style={Number(scene?.sunRiseSunSetConfig?.eventTiming) === 60 ? ssrs_eventTiming_style_selected : ssrs_eventTiming_style}
                                        onClick={() => handleEventTiming(60, 'afterOneHour')}
                                    > After 1 Hour</div>
                                    <div
                                        style={Number(scene?.sunRiseSunSetConfig?.eventTiming) === 30 ? ssrs_eventTiming_style_selected : ssrs_eventTiming_style}
                                        onClick={() => handleEventTiming(30, 'afterThirtyMins')}
                                    > After 30 Minutes</div>
                                    <div
                                        style={Number(scene?.sunRiseSunSetConfig?.eventTiming) === 10 ? ssrs_eventTiming_style_selected : ssrs_eventTiming_style}
                                        onClick={() => handleEventTiming(10, 'afterTenMins')}
                                    > After 10 minutes</div>
                                    <div
                                        style={Number(scene?.sunRiseSunSetConfig?.eventTiming) === -10 ? ssrs_eventTiming_style_selected : ssrs_eventTiming_style}
                                        onClick={() => handleEventTiming(-10, 'beforeTenMins')}
                                    > Before 10 Minutes</div>
                                    <div
                                        style={Number(scene?.sunRiseSunSetConfig?.eventTiming) === -30 ? ssrs_eventTiming_style_selected : ssrs_eventTiming_style}
                                        onClick={() => handleEventTiming(-30, 'beforeThirtyMins')}
                                    > Before 30 Minutes</div>
                                    <div
                                        style={Number(scene?.sunRiseSunSetConfig?.eventTiming) === -60 ? ssrs_eventTiming_style_selected : ssrs_eventTiming_style}
                                        onClick={() => handleEventTiming(-60, 'beforeOneHour')}
                                    > Before 1 Hour</div>
                                    <div
                                        style={(scene?.sunRiseSunSetConfig?.eventType === 'sunset' && Number(scene?.sunRiseSunSetConfig?.eventTiming) === 0) ? ssrs_eventTiming_style_selected : ssrs_eventTiming_style}
                                        onClick={() => handleEventTiming(0, 'sunset')}
                                    > Sunset</div>
                                </div>}
                            </div>

                            {/* Days Of Week */}
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                                <div><strong style={{ fontWeight: 'bolder', fontSize: '1.2em' }}>Select Days to Repeat</strong></div>
                                <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                                    {_daysOfWeek.map((item, index) => (
                                        <div
                                            key={'dow' + index}
                                            className='cursor-pointer'
                                            style={[...scene?.sunRiseSunSetConfig?.daysOfWeek].includes(item.value) ? dow_style_selected : dow_style}
                                            onClick={() => handleDaysOfWeek(item)}
                                        >
                                            <div>{item.label}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>}

                        {/* Control Device */}
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                            <div><strong style={{ fontWeight: 'bolder', fontSize: '1.2em' }}>Control Device</strong></div>
                            {(scene?.data?.control?.on.length || scene?.data?.control?.off.length) ? <div><strong style={{ fontWeight: 'bolder', color: 'grey' }}>{[...scene?.data?.control?.on, ...scene?.data?.control?.off].length ?? 'No'} Device Changed</strong></div> : <div><strong style={{ fontWeight: 'bolder', color: 'grey' }}>{'No'} Device Changed</strong></div>}
                        </div>

                        {/* Add Change */}
                        <div style={{ textAlign: 'center' }}>
                            <button
                                className='cursor-pointer'
                                style={{
                                    background: ThemeColor.pirPrimary,
                                    color: 'white',
                                    padding: '10px 20px',
                                    display: 'inline',
                                    border: 'none',
                                    borderRadius: '4px'
                                }}
                                onClick={() => {
                                    setSideDrawer('open');
                                }}
                            >
                                Add Change
                            </button>
                        </div>


                    </div>

                </div>

                <SideDrawer drawer={sideDrawer} setDrawer={setSideDrawer} background={'white'} title={"Device Settings"} zIndex={22}>
                    {scene ? <SelectDevices data={scene} setData={setScene} handleOnChange={handleOnChange} handleOffChange={handleOffChange} handleDefaultChange={handleDefaultChange} /> : <CircleBarSpinner />}
                </SideDrawer>
            </> : <CircleBarSpinner />}
        </>
    )
}
const inputStyleAdd = {
    width: '100%',
    border: 'none',
    borderBottom: '1px solid black',
    color: 'black',
    outline: 'none'
}
const inputStyleUpdate = {
    width: '100%',
    border: 'none',
    borderBottom: '1px solid black',
    color: 'grey',
    outline: 'none'
}
const ssrs_eventTiming_style = {
    // borderBottom: `1px solid ${ThemeColor.pirPrimary}`,
    padding: '10px 0px',
    width: '90%',
    cursor: 'pointer',
    width: '100%'
}
const ssrs_eventTiming_style_selected = {
    // borderBottom: `1px solid ${ThemeColor.pirPrimary}`,
    padding: '10px 0px',
    width: '90%',
    cursor: 'pointer',
    width: '100%',
    background: '#d6c0fa'
}
const dow_style_selected = {
    height: '45px',
    width: '45px',
    textAlign: 'center',
    background: ThemeColor.pirPrimary,
    border: `1px solid ${ThemeColor.pirPrimary}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    borderRadius: '50%',

}
const dow_style = {
    height: '45px',
    width: '45px',
    textAlign: 'center',
    background: ThemeColor.white,
    border: `1px solid ${ThemeColor.black}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: ThemeColor.black,
    borderRadius: '50%',

}
export default SceneDetail