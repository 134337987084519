import React, { useEffect, useState } from 'react';
import { FaPlay } from "react-icons/fa";
import ThemeColor from '../../../assets/theme/ThemeColor';
import { UserService } from '../../../services/UserService';
import { User } from '../../../storage/store';
import CircleBarSpinner from '../../../components/common/CircleBarSpinner';
import { useToggleTop } from '../../../components/common/ToggleTop';
import SceneDetail from './SceneDetail';
import BottomSheet from '../../../components/common/BottomSheet';
import SideDrawer from '../../../components/common/SideDrawer';
import SelectDevices from '../../../components/common/SelectDevices';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useUser } from '../../../Context/context';

const SceneCard = ({ data, color, playbtnCircleColor, playbtnColor, handleScenedetail }) => {
    
    playbtnCircleColor = playbtnCircleColor ?? "white";
    playbtnColor = playbtnColor ?? "black";
    const setToggleTop = useToggleTop();
    const handleScene = async () => {
        if (Object.keys(data).includes('_id')) {
            const val = window.confirm(`Sure start ${data.name} scene?`);
            if (val) {
                const response = UserService.triggerScene({ sceneId: data?._id });
                setToggleTop("Scene started.", "green");
            }
            else
                setToggleTop("Scene not Triggered.", "red");
        }else console.log('not a scene');



    }
    return (
        <div style={{
            background: color,
            height: '170px',
            minWidth: '150px',
            maxWidth: '150px',
            borderRadius: '18px',
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'column',
            boxShadow: '1px 1px 10px grey',
            gap: '20px',
            padding: '20px 0px 0px 0px',
        }}>
            <div style={{
                color: 'black',
                backgroundColor: playbtnCircleColor,
                textAlign: 'center',
                borderRadius: '50%',
                height: '50px',
                width: '50px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <FaPlay style={{ fontSize: '20px', cursor: 'pointer', color: playbtnColor }} onClick={handleScene} />
            </div>
            <div style={{
                background: 'white',
                width: '99%',
                height: '10%',
                flexGrow: '1',
                borderRadius: '18px',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '10px',
                cursor: 'pointer',
            }}
                onClick={() => handleScene()}
            >
                <p>{data?.name}</p>
            </div>
        </div>

    );
};

const SceneContainer = () => {
    const { allDevices } = useUser();
    const navigate = useHistory();
    const [scenes, setScenes] = useState(null);
    const [bottomSheet, setBottomSheet] = useState('');
    const [sideDrawer, setSideDrawer] = useState('');
    const [selectedDevices, setSelectedDevices] = useState({ on: [], off: [] });
    let PropertyId = User.getPropertyId();
    const [selectedScene, setSelectedScene] = useState();

    const getScenes = async () => {
        const response = await UserService.getScenes(PropertyId);
        console.log(response);
        const scenes = response.length ? response.filter((item)=>item.sceneType==="tapToStart") : [];
        setScenes(scenes);
    }

    useEffect(() => {
        if(PropertyId===null || PropertyId===undefined){
            PropertyId = User.getPropertyId();
        }
        getScenes();
    }, [PropertyId])

    const handlePayload = (data) => {
        selectedDevices.on = [...data?.on];
        selectedDevices.off = [...data?.off];
        setSelectedDevices({ ...selectedDevices });
    }

    const handleScenedetail = (data) => {
        setBottomSheet('open');
        selectedDevices.on = data?.data?.control?.on;
        selectedDevices.off = data?.data?.control?.off;
        setSelectedDevices({ ...selectedDevices });
        setSelectedScene({ ...data });
        // console.log(data);
    }
    // console.log(selectedDevices);
    return (
        !(scenes == null || scenes == undefined)
            ?
            [...scenes]?.length > 0
                ?
                <>
                    <div className='scene-container' style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        gap: '40px', overflowY: 'hidden',
                        overflowX: 'scroll',
                        transition: 'all 2s',
                        flexGrow: 'row wrap',
                        padding: '20px'
                    }}>
                        {[...scenes].map((scene, index) => (
                            <SceneCard
                                key={index + 'scene'}
                                data={scene}
                                color={ThemeColor.quickActions[index % ThemeColor.quickActions.length]}
                                handleScenedetail={handleScenedetail}
                            />
                        ))}
                    </div>
                    <BottomSheet drawer={bottomSheet} setDrawer={setBottomSheet} zIndex={20}>
                        <SceneDetail selectedDevices={selectedDevices} data={selectedScene} title={'Update Scene'} setSideDrawer={setSideDrawer} setBottomSheet={setBottomSheet} getScenes={getScenes} />
                    </BottomSheet>
                    <SideDrawer drawer={sideDrawer} setDrawer={setSideDrawer} background={'white'} title={"Update Device Settings"} zIndex={22}>
                        <SelectDevices setDevices={setSelectedDevices} devices={selectedDevices} />
                    </SideDrawer>
                </>

                : <div style={{
                    textAlign: 'center',
                    margin: '0 auto'

                }}
                onClick={()=>{navigate.push('scenes', {devices:allDevices})}}
                >
                    <SceneCard
                        data={{ name: "Scenes Are listed here." }}
                        color={"white"}
                        playbtnColor={'white'}
                        playbtnCircleColor={ThemeColor.SocketSecondary}
                       
                        // handleScenedetail={()=>console.log('Not a scene')}
                    />
                </div>
            : <CircleBarSpinner />

    );
};

export default SceneContainer;
