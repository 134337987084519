import React, { useEffect, useState } from 'react'
import Main from '../../../components/layout/Main'
import ThemeColor from '../../../assets/theme/ThemeColor'
import Switch from "react-switch";
import { IoIosTime, IoIosRadioButtonOn } from "react-icons/io";
import { MdDateRange, MdDelete } from "react-icons/md";
import moment from 'moment';
import { UserService } from '../../../services/UserService';
import ToggleTop from '../../../components/common/ToggleTop';
import { useUser } from '../../../Context/context';
import UpdateRoutine from './UpdateRoutine';

const RoutineCard = ({ data, deleteRoutineCard, bgColor, isDelete, updateMsg, openUpdateSheet }) => {
    const [checked, setChecked] = useState(false);
    const [active, setActive] = useState();
    const [openSheet, setOpenSheet] = useState(false);

    const showDrawer = () => {
        setOpenSheet(true);
    };
    const closeDrawer = () => setOpenSheet(false);

    const week = {
        '0': 'Sun', '1': 'Mon', '2': 'Tue', '3': 'Wed', '4': 'Thu', '5': 'Fri', '6': 'Sat'
    }

    const handleChange = async (id, status) => {
        setActive(!active);
        try {
            const result = await UserService.enableRoutine(id, { disable: !status });
        } catch (e) {
            // console.log(e);
        }
    };

    useEffect(() => {
        setActive(!data.disabled)
        if (updateMsg === 'green') {
            setOpenSheet(false);
        } if (updateMsg === 'red') {
            setOpenSheet(false);
        }
    }, [updateMsg])

    const IST_time = moment(data.data.time, 'hh:mm a')
        .utc() // Convert to UTC time
        .utcOffset('+05:30') // Set IST (Indian Standard Time) offset
        .format('HH:mm A'); // Format the time

    // console.log(IST_time);

    return (
        <>
            {/* {updateMsg === 'green' ? <ToggleTop color='green' message="Routine deleted successfully" />
                : updateMsg === 'red' ? <ToggleTop color='red' message="Routine not deleted" />
                    : null
            } */}
            <div className="zoom-on-hover" style={{ ...styles.container, backgroundColor: active ? bgColor : '#E9E9E9', position: 'relative', }}>
                <div style={styles.TitleContailer}>
                    <b style={{ color: active ? ThemeColor.white : '#727272', fontSize: '1em', }}>{data.data.name}</b>
                    <Switch
                        checked={active}
                        onChange={() => handleChange(data._id, data.disabled)}
                        onColor="#fff"
                        onHandleColor={bgColor}
                        offColor='#fff'
                        offHandleColor='#bdbdbd'
                        height={20}
                        width={40}
                    />
                </div>
                <div onClick={showDrawer}>
                    <div style={styles.iconContainer}>
                        <IoIosTime style={{ color: active ? ThemeColor.white : '#727272', fontSize: 25, }} />
                        <b style={{ color: active ? ThemeColor.white : '#727272', fontSize: '1.3em', marginLeft: '10px' }}>
                            {moment.utc(data.data.time, 'HH:mm').utcOffset('+05:30').format('hh:mm A')}</b>
                    </div>
                    <div style={styles.iconContainer}>
                        <MdDateRange style={{ color: active ? ThemeColor.white : '#727272', fontSize: 20, marginLeft: '3px' }} />
                        {data.data.weekDays && data.data.weekDays.length
                            ? data.data.weekDays
                                .map((day, idx) => {
                                    const lab = week[day];
                                    return (
                                        <small key={idx} style={{ color: active ? ThemeColor.white : '#727272', marginLeft: '12px' }}>{lab}</small>
                                    );
                                })
                            : null}
                    </div>
                    <div style={styles.iconContainer}>
                        <IoIosRadioButtonOn style={{ color: active ? ThemeColor.white : '#727272', fontSize: 20, marginLeft: '3px' }} />
                        <b style={{ color: active ? ThemeColor.white : '#727272', fontSize: '1em', marginLeft: '12px' }}>
                            {[...data.data.control.off, ...data.data.control.on].length > 1
                                ? [...data.data.control.off, ...data.data.control.on].length + ' Devices Changed'
                                : [...data.data.control.off, ...data.data.control.on].length + ' Device Changed'}
                        </b>
                    </div>
                    {isDelete ? (
                        <div onClick={() => deleteRoutineCard(data._id, data.data.name)} className={isDelete ? 'routine-delete-icon-appear' : 'routine-delete-icon-hide'} style={styles.delelteicon}>
                            <MdDelete style={{ color: ThemeColor.white, fontSize: 20, alignSelf: 'center' }} />
                        </div>
                    ) : null}
                    {openSheet == true ? (
                        <UpdateRoutine data={data} closeDrawer={closeDrawer} />
                    ) : null}
                </div>
            </div>
        </>
    )
}

const styles = {
    container: {
        color: 'white',
        borderRadius: '20px',
        padding: '20px 20px',
        width: '300px',
        boxSizing: 'border-box',
        height: '180px',
        flexShrink: 0,
        cursor: 'pointer',
        transition: 'all 1s',
        gap: '40px',
    },
    TitleContailer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%'
    },
    iconContainer: {
        marginTop: '15px',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center'
    },
    delelteicon: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        backgroundColor: ThemeColor.darkred,
        width: '40px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '20px',
        cursor: 'pointer',
    }
}
export default RoutineCard